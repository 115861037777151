@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    font-family: "Montserrat", sans-serif !important;
    scroll-behavior: smooth;
}
body{
    min-height: 100vh;
}
.main-container {
    width: 100vw;
    display: flex;
    justify-content: center;
}

*::-webkit-scrollbar-track,
*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-track
    *::-webkit-scrollbar
    *::-webkit-scrollbar-thumb,
.root
    *
    *::-webkit-scrollbar-track
    .root
    *
    *::-webkit-scrollbar
    .root
    *
    *::-webkit-scrollbar-thumb {
    display: none !important;
}


.Mui-completed{
    color:rgba(0, 0, 0, 0.38) !important;
}
.notistack-SnackbarContainer{
    margin-top: 120px;
}
  